<template>
  <div class="installationTabs">
    <ul class="nav nav-tabs">
      <li
        v-if="
          !(
            authenticationHasRole('scope_customer') &&
            authenticationHasRole('zerolevel')
          )
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('operation') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ $t("operations") }}</span>
        </router-link>
      </li>
      <LoadingPlaceholder
        v-if="envLoading"
        size="small"
        class="m-0"
      />
      <template v-else>
        <li
          v-if="!authenticationHasRole('zerolevel') && showTrackerV4 == true"
          class="nav-item"
        >
          <router-link
            :to="{ path: pathTo('tracker') }"
            class="nav-link"
            active-class="active"
            tag="a"
          >
            <span>Tracker V4</span>
          </router-link>
        </li>
        <li
          v-if="!authenticationHasRole('zerolevel') && showTrackerV3 == true"
          class="nav-item"
        >
          <router-link
            :to="{ path: pathTo('trackerv3') }"
            class="nav-link"
            active-class="active"
            tag="a"
          >
            <span>Tracker V3</span>
          </router-link>
        </li>
        <li
          v-if="showEventMovie == true"
          class="nav-item"
        >
          <router-link
            :to="{ path: pathTo('eventmovie') }"
            class="nav-link"
            active-class="active"
            tag="a"
          >
            <span>Event Movie</span>
          </router-link>
        </li>
      </template>
      <li
        v-if="
          showTab([
            'destinationos',
            'competition',
            'it service',
            'skiline online',
          ]) &&
            authenticationHasRole('scope_staff') &&
            (authenticationHasRole('admin') ||
            authenticationHasRole('firstlevel') ||
            authenticationHasRole('secondlevel'))
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('administration') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Administration</span>
        </router-link>
      </li>
      <li
        v-if="
          !(
            authenticationHasRole('scope_customer') &&
            authenticationHasRole('zerolevel')
          )
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('freshdesk') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Tickets / Issues</span>
        </router-link>
      </li>
      <li
        v-if="
          showTerminalSales ||
            showTerminalStatus ||
            (showTab(['terminal', 'mediastreamer']) &&
            authenticationHasRole('scope_customer') &&
            (authenticationHasRole('secondlevel') ||
            authenticationHasRole('zerolevel')))
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('terminal-status') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ $t("status") }}</span>
        </router-link>
      </li>
      <li
        v-if="showTab(['terminal']) && authenticationHasRole('scope_staff')"
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('terminal-tool') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Terminal</span>
        </router-link>
      </li>
      <li
        v-if="showTerminalSales"
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('terminalSales') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ $t("terminalForm.salesTransactions") }}</span>
        </router-link>
      </li>
      <li
        v-if="
          showTab([
            'skimovie',
            'bikemove',
            'photopoint',
            'phototrap',
            'photostart',
            'speedcheck',
            'time measurement',
            'eventmovie',
          ])
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('session') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ $t("session") }}</span>
        </router-link>
      </li>
      <li
        v-if="
          !(
            authenticationHasRole('scope_customer') &&
            authenticationHasRole('zerolevel')
          )
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('detail') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ $t("details") }}</span>
        </router-link>
      </li>
      <li
        v-if="
          !authenticationHasRole('zerolevel') &&
            !authenticationHasRole('firstlevel') &&
            !authenticationHasRole('firstlevel_connector') &&
            showTab(['skimovie', 'eventmovie'])
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('resources') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Resources</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          v-if="
            !showTab([
              'altimeter base service',
              'core service',
              'destinationos',
              'competition',
              'panocam',
              'emma',
              'it service',
              'skiline online',
            ]) || destinationOsDevice
          "
          :to="{ path: pathTo('devices') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ $t("devices") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          v-if="!authenticationHasRole('zerolevel')"
          :to="{ path: pathTo('installation-ips') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>IP Whitelist</span>
        </router-link>
      </li>
      <li
        v-if="
          showTab([
            'photostart',
            'skimovie',
            'biketrack',
            'speedcheck',
            'phototrap',
            'photopoint',
          ])
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('reports') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ $t("reports") }}</span>
        </router-link>
      </li>
      <li
        v-if="showTab(['photopoint'])"
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('photopoint') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Photopoint</span>
        </router-link>
      </li>
      <li
        v-if="!authenticationHasRole('zerolevel') && showTab(['phototrap'])"
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('phototrap') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Phototrap</span>
        </router-link>
      </li>
      <li
        v-if="
          !authenticationHasRole('zerolevel') &&
            showTab(['phototrap', 'speedcheck', 'photostart'])
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('actioncam') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>ActionCam</span>
        </router-link>
      </li>
      <li
        v-if="
          !authenticationHasRole('firstlevel') &&
            !authenticationHasRole('firstlevel_connector') &&
            !authenticationHasRole('zerolevel') &&
            showConverterConfigs
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('converterConfigs') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ "Converter" }}</span>
        </router-link>
      </li>
      <li
        v-if="
          authenticationHasRole('scope_staff') &&
            (authenticationHasRole('secondlevel') ||
            authenticationHasRole('firstlevel') ||
            authenticationHasRole('admin')) &&
            showTab(['altimeter base service'])
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('converterSections') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ "Sections" }}</span>
        </router-link>
      </li>
      <li
        v-if="
          authenticationHasRole('scope_staff') &&
            (authenticationHasRole('secondlevel') ||
            authenticationHasRole('firstlevel') ||
            authenticationHasRole('admin')) &&
            showTab(['altimeter base service'])
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('converterRoutingRules') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ $t("routingRulesForms.routingRules") }}</span>
        </router-link>
      </li>
      <li
        v-if="showTab(['panocam'])"
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('panocam-image') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ $t("image") }}</span>
        </router-link>
      </li>
      <li
        v-if="
          notificationSwitzerland && !authenticationHasRole('scope_customer')
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('notificationSwitzerland') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Work Notification</span>
        </router-link>
      </li>
      <li
        v-if="
          !authenticationHasRole('zerolevel') &&
            showTab(['photostart', 'speedcheck', 'phototrap', 'photopoint'])
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('svg-template') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>SVG Template</span>
        </router-link>
      </li>
      <li
        v-if="
          showTab([
            'destinationos',
            'competition',
            'emma',
            'it service',
            'skiline online',
          ])
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('software-components') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Software Components</span>
        </router-link>
      </li>
      <li
        v-if="
          showTab([
            'destinationos',
            'competition',
            'emma',
            'it service',
            'skiline online',
          ]) &&
            authenticationHasRole('scope_staff') && (
              authenticationHasRole('admin') ||
            authenticationHasRole('secondlevel')
            )
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('software-components-versions') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Versions</span>
        </router-link>
      </li>
      <li
        v-if="
          showTab(['destinationos']) &&
            (authenticationHasRole('scope_staff') ||
            (authenticationHasRole('scope_customer') &&
            (authenticationHasRole('admin') ||
            authenticationHasRole('secondlevel') ||
            authenticationHasRole('firstlevel'))))
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('order-error-logs') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Order Error Log</span>
        </router-link>
      </li>
      <li
        v-if="
          authenticationHasRole('scope_staff') ||
            (authenticationHasRole('scope_customer') &&
            (authenticationHasRole('admin') ||
            authenticationHasRole('secondlevel') ||
            authenticationHasRole('firstlevel')))
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('destination-os-emma-dashboard') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Dashboard</span>
        </router-link>
      </li>
      <li
        v-if="showTab(['destinationos', 'emma'])"
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('sla') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>SLA</span>
        </router-link>
      </li>
      <li
        v-if="
          authenticationHasRole('scope_staff') ||
            authenticationHasRole('scope_customer')
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('installation-relations') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ "Relations" }}</span>
        </router-link>
      </li>
      <li
        v-if="
          authenticationHasRole('scope_staff') && authenticationHasRole('admin')
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('webhooks') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ "Webhooks" }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ path: pathTo('installation-contacts') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>{{ $t("installationDetailOperationView.contacts") }}</span>
        </router-link>
      </li>
      <li
        v-if="
          authenticationHasRole('crud_events') ||
            ((authenticationHasRole('scope_staff') ||
            authenticationHasRole('scope_customer')) &&
            (authenticationHasRole('admin') ||
            authenticationHasRole('zerolevel') ||
            authenticationHasRole('firstlevel') ||
            authenticationHasRole('secondlevel')))
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('event') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Event</span>
        </router-link>
      </li>
      <li
        v-if="
          (authenticationHasRole('scope_staff') ||
            authenticationHasRole('scope_customer')) &&
            (authenticationHasRole('admin') ||
            authenticationHasRole('firstlevel') ||
            authenticationHasRole('secondlevel'))
        "
        class="nav-item"
      >
        <router-link
          :to="{ path: pathTo('changelog') }"
          class="nav-link"
          active-class="active"
          tag="a"
        >
          <span>Changelog</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { authenticationMixin } from "@/mixins/authenticationMixin";

export default {
  name: "InstallationTabs",
  mixins: [authenticationMixin],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    installationObject: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      showEventMovie: null,
      showTrackerV4: null,
      envLoading: true,
      showTrackerV3: null,
      notificationSwitzerland: null,
      showTerminalSales: false,
      showTerminalStatus: false,
      showConverterConfigs: false,
      destinationOsDevice: false,
    };
  },
  created () {
    this.getEnvironmentInfo();
  },
  methods: {
    showTab (allowedTypes) {
      if (this.installationObject.installationType == null) {
        return false;
      }
      let type = this.installationObject.installationType.trim().toLowerCase();
      return allowedTypes.includes(type);
    },
    pathTo (endpoint) {
      return "/installation/" + this.installationId + "/" + endpoint;
    },
    getEnvironmentInfo () {
      //this.notificationSwitzerland = true; //comment in to see work notification tab in development
      this.axios
        .get(`/Installation/GetEnvironmentInformation/${this.installationId}`)
        .then((result) => {
          if (result == null) {
            return;
          }
          if (result.data == null) {
            return;
          }
          if (result.data.showTrackerV4 == null) {
            return;
          }
          this.showTrackerV4 = result.data.showTrackerV4;
          this.showTrackerV3 = result.data.showTrackerV3;
          this.showEventMovie = result.data.showEventMovie;
          this.notificationSwitzerland = result.data.notificationSwitzerland;
          this.showTerminalSales = result.data.showTerminalSales;
          this.showTerminalStatus = result.data.showTerminalStatus;
          this.showConverterConfigs = result.data.showConverterConfigs;
          this.destinationOsDevice = result.data.destinationOsDevice;
        })
        .finally(() => {
          this.envLoading = false;
        });
    },
  },
};
</script>
